<template>
  <div class="home">
    <headers/>
    <div>
      <div class="iFZuZK">
        <div class="jAPxxm">
          <img class="videoImg" :src="require('@/assets/image/video.png')" alt="" />
          
        </div>
        <div class="frPNiP">
            <div class="ksEuhS">
              <div class="cTJIxw">
                <p>数壤科技（苏州）有限公司是以人工智能为驱动，碳管理<br/>
与生产及设备管理融合为主要特点的工业数字化转型平台服务商。</p>
              </div>
              <div class="isHFlM">
                <!-- <p>数壤科技（苏州）有限公司是专业的工业元宇宙系统和工业数字化转型平台服务商。</p>
                <p>公司拥有完全自主研发的全套软硬件和SaaS服务平台，对制造业中小企业进行快速数字化转型。</p>
                <p>进而打造以真实数据为底层，产业链为依托的工业元宇宙平台。帮助大量制造业客户实现价值，</p>
                <p>由内而外地提升企业数字化运营能力，迈入工业云宇宙时代。</p> -->
                <p>数壤科技持续研发并投入应用的生产能源设备一体化平台（数字厂长），是通过植入AI算法的智能物联硬件，使<br/>用云端算力构筑起的，以工业大数据为核心，快速服务于制造业中小企业的SaaS平台。<br/>
平台拥有自主知识产权的各种人工智能算法、AIoT数采硬件，做到以标准化的SaaS产品快速服务于广大离散制<br/>造业中小企业的生产过程、精细能耗及全设备的数字化。通过数据挖掘技术、AI技术为用户提供能耗、人工、碳<br/>排、设备等多维度生产成本分析控制服务，在工业过程透明化管理，节能降碳，结合生产过程数据和设备实时<br/>状态的人效拉动提升，及设备智能运维等综合方面，帮助企业降本增效。同时利用人工智能+物联网+区块链技<br/>术优势为用户提供产品碳足迹和ESG服务，助力构建产业链低碳生态。</p>
              </div>
              <div class="fReABp">
                <button class="insUpY"><a href="/productoverview">查看更多 &gt;</a></button>
                <a class="iZGBsp" id="landing-video-link">
                  <div class="kFYMXL">
                    <img src="https://files.blacklake.cn/ow-images/home-2019/icons/video-play.svg"
                      alt="观看产品介绍视频" />观看产品介绍视频
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="iaIRJu"></div>
      </div>
    </div>
    <div class="homepage_bg_1">      
      <div class="home_content">
        <div class="introduce introduce-first">
          <div class="introduce-title"><img :src="require('@/assets/image/introduce.png')" alt=""></div>
          <div class="introduce-to"><a href="/productoverview">详细产品<i class="el-icon-right"></i></a></div>
        </div>
        <div class="introduce introduce-second">
          <div class="introduce-content">
            <p>更懂制造业企业的贴心助手</p>
            <img class="videoImg" :src="require('@/assets/image/introduce-content.png')" alt="" />
          </div>
          <div class="introduce-text">
            <div class="introduce-text-title">能</div>
            <p>电费节省方案，大幅节省费用。</p>
            <p>碳交易预先方案， 碳市场占领先机。</p>
            <div class="introduce-text-title">产</div>
            <p>排产——优先排产，紧跟订单，精准派工。</p>
            <p>生产——手机、扫脸、触屏多项报工</p>
            <p>屏显——大屏显示工单，实时清晰进度</p>
            <p>设备——OEE显示，实时显示状态</p>
            <div class="introduce-text-title">供</div>
            <p>原料入库—— 入库直接连通生产，数据实时显示</p>
            <div class="introduce-text-title">销</div>
            <p>精准客户推荐，订单自转工单</p>
            <div class="introduce-text-title">管</div>
            <p>整体分析——全程跟踪显示掌握工厂内外。</p>
          </div>
        </div>
        <div class="introduce introduce-3">
          <template v-if="showNum">
          <div class="datas">
            <img class="icon" :src="require('@/assets/image/xiaolv.png')" alt="">
            <p class="names">效率提升</p>
            <div class="line"></div>
            <div class="up">
              <div class="one">
                <div class="up-name">员工效率提升</div>
                <div class="up-line">
                  <div class="progress" style="width: 20%;">
                    <div class="progress-bg"></div>
                  </div>
                </div>
                <div class="up-num">
                  <i class="el-icon-top add"></i>
                  <CountTo :startVal='0' :endVal='20' :duration='4000' />%
                </div>
              </div>
              <div class="one">
                <div class="up-name">工厂效率提升</div>
                <div class="up-line">
                  <div class="progress" style="width: 30%;">
                    <div class="progress-bg"></div>
                  </div>
                </div>
                <div class="up-num">
                  <i class="el-icon-top add"></i>
                  <CountTo :startVal='0' :endVal='30' :duration='4000' />%
                </div>
              </div>
              <div class="one">
                <div class="up-name">订单交付效率提升</div>
                <div class="up-line">
                  <div class="progress" style="width: 60%;">
                    <div class="progress-bg"></div>
                  </div>
                </div>
                <div class="up-num">
                  <i class="el-icon-top add"></i>
                  <CountTo :startVal='0' :endVal='60' :duration='4000' />%
                </div>
              </div>
            </div>
          </div>
          <div class="datas">
            <img class="icon" :src="require('@/assets/image/wuliao.png')" alt="">
            <p class="names">物料节省</p>
            <div class="line"></div>
            <div class="up">
              <div class="one">
                <div class="up-name">物料成本节省</div>
                <div class="up-line">
                  <div class="progress" style="width: 10%;">
                    <div class="progress-bg down"></div>
                  </div>
                </div>
                <div class="up-num">
                  <i class="el-icon-bottom down"></i>
                  <CountTo :startVal='0' :endVal='10' :duration='4000' />%
                </div>
              </div>
              <div class="one">
                <div class="up-name">能源费用节省</div>
                <div class="up-line">
                  <div class="progress" style="width: 20%;">
                    <div class="progress-bg down"></div>
                  </div>
                </div>
                <div class="up-num">
                  <i class="el-icon-bottom down"></i>
                  <CountTo :startVal='0' :endVal='20' :duration='4000' />%
                </div>
              </div>
              <div class="one">
                <div class="up-name">沟通成本节省</div>
                <div class="up-line">
                  <div class="progress" style="width: 30%;">
                    <div class="progress-bg down"></div>
                  </div>
                </div>
                <div class="up-num">
                  <i class="el-icon-bottom down"></i>
                  <CountTo :startVal='0' :endVal='30' :duration='4000' />%
                </div>
              </div>
              <div class="one">
                <div class="up-name">用工成本节省</div>
                <div class="up-line">
                  <div class="progress" style="width: 16%;">
                    <div class="progress-bg down"></div>
                  </div>
                </div>
                <div class="up-num">
                  <i class="el-icon-bottom down"></i>
                  <CountTo :startVal='0' :endVal='16' :duration='4000' />%
                </div>
              </div>
            </div>            
          </div>
          <div class="datas">
            <img class="icon" :src="require('@/assets/image/fengxian.png')" alt="">
            <p class="names">风险减少</p>
            <div class="line"></div>
            <div class="up">
              <div class="one">
                <div class="up-name">仓储风险减少</div>
                <div class="up-line">
                  <div class="progress" style="width: 20%;">
                    <div class="progress-bg down"></div>
                  </div>
                </div>
                <div class="up-num">
                  <i class="el-icon-bottom down"></i>
                  <CountTo :startVal='0' :endVal='20' :duration='4000' />%
                </div>
              </div>
              <div class="one">
                <div class="up-name">订单风险减少</div>
                <div class="up-line">
                  <div class="progress" style="width: 50%;">
                    <div class="progress-bg down"></div>
                  </div>
                </div>
                <div class="up-num">
                  <i class="el-icon-bottom down"></i>
                  <CountTo :startVal='0' :endVal='50' :duration='4000' />%
                </div>
              </div>
              <div class="one">
                <div class="up-name">能源风险减少</div>
                <div class="up-line">
                  <div class="progress" style="width: 30%;">
                    <div class="progress-bg down"></div>
                  </div>
                </div>
                <div class="up-num">
                  <i class="el-icon-bottom down"></i>
                  <CountTo :startVal='0' :endVal='30' :duration='4000' />%
                </div>
              </div>
            </div>
          </div>
          <div class="datas">
            <img class="icon" :src="require('@/assets/image/xiaoshou.png')" alt="">
            <p class="names">销售提升</p>
            <div class="line"></div>
            <div class="up" style="display: block;">
              <div class="one">
                <div class="up-name">订单进度提高</div>
                <div class="up-line">
                  <div class="progress" style="width: 50%;">
                    <div class="progress-bg"></div>
                  </div>
                </div>
                <div class="up-num">
                  <i class="el-icon-top add"></i>
                  <CountTo :startVal='0' :endVal='50' :duration='4000' />%
                </div>
              </div>
              <div class="one" style="margin-top: 1.5rem;">
                <div class="up-name">销售获客提升</div>
                <div class="up-line">
                  <div class="progress" style="width: 50%;">
                    <div class="progress-bg"></div>
                  </div>
                </div>
                <div class="up-num">
                  <i class="el-icon-top add"></i>
                  <CountTo :startVal='0' :endVal='50' :duration='4000' />%
                </div>
              </div>
            </div>
          </div>
          </template>
        </div>
        <div class="introduce introduce-4" style="margin-top: 1.2rem">
          <div class="godDone">
            <div class="icon"><img :src="require('@/assets/image/well1.png')" alt="" /></div>
            <div class="text">
              <p class="names">一天上线</p>专人现场部署，一天上线
            </div>
          </div>
          <div class="godDone">
            <div class="icon"><img :src="require('@/assets/image/well2.png')" alt="" /></div>
            <div class="text">
              <p class="names">轻松上手</p>员工简单学会,10分钟上手
            </div>
          </div>
          <div class="godDone">
            <div class="icon"><img :src="require('@/assets/image/well3.png')" alt="" /></div>
            <div class="text">
              <p class="names">配置灵活</p>可操作空间大,灵活配置
            </div>
          </div>
        </div>
        <div class="introduce introduce-4">
          <div class="godDone">
            <div class="icon"><img :src="require('@/assets/image/well4.png')" alt="" /></div>
            <div class="text">
              <p class="names">全厂打通</p>各功能数据联动,打通全厂内容
            </div>
          </div>
          <div class="godDone">
            <div class="icon"><img :src="require('@/assets/image/well5.png')" alt="" /></div>
            <div class="text">
              <p class="names">专业实施</p>专业人才现场安装,解惑答疑
            </div>
          </div>
          <div class="godDone">
            <div class="icon"><img :src="require('@/assets/image/well6.png')" alt="" /></div>
            <div class="text">
              <p class="names">专家配置</p>专家提供方案,助力企业
            </div>
          </div>
        </div>
      </div>
      <div class="colorDiv"></div>
    </div>
    <indexPart2/>
    <footers/>
  </div>
</template>

<script>
import $ from 'jquery'
import headers from '@/components/head';
import footers from '@/components/foot'
import CountTo from 'vue-count-to'
import indexPart2 from '@/views/home/indexPart2'
import redirect from "@/utils/redirect";
import pcRem from "@/utils/pcrem";
export default {
  mixins: [redirect,pcRem],
  components: {
    headers,
    footers,
    CountTo,
    indexPart2
  },
  data() {
    return {
      showNum: false,
    }
  }, 
  mounted() {
    $('.home').scroll(() => {
      let scrollTop = $('.home').scrollTop()
      let home2Offsettop = $('.homepage_bg_1').offset().top
      let home2bottomsettop = $('.homepage_bg_1')[0].offsetTop + $('.homepage_bg_1').height()
      if (home2Offsettop - 100 < 0 && scrollTop < home2bottomsettop - 200) {
        this.showNum = true
      }
      if (scrollTop > home2bottomsettop - 200 || scrollTop < home2Offsettop) {
        this.showNum = false
      }
    })
  },
  methods: {
  },
}
</script>
<style lang="scss" scoped>
//1920*1080为设计稿，头部字体大小为25px
$ratio: 1920 / 76.8; // 缩放比   25

// 返回对应屏幕下的rem值
@function px2rem($px) {
  @return $px / $ratio +rem;
}

a {
  color: white;
}

.home {
  height: 100vh;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}



.iFZuZK {
  height: 100vh;
  border-right: none;
  border-left: none;
  position: relative;

  .jAPxxm {
    position: fixed;
    z-index: -1;
    bottom: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    overflow: hidden;

    .videoImg {
      position: absolute;
      z-index: 0;
      bottom: 0px;
      min-height: 100vh;
      min-width: 100vw;
      background-size: cover;
      background-color: rgba(0, 0, 0, 0.6);
      animation: video 10s infinite;

      @keyframes video {
        0% {
          scale: 1;
        }

        100% {
          scale: 1.2;
        }
      }
    }    
  }
  .frPNiP {
      width: 100vw;
      height: 100vh;
      position: relative;
      text-align: center;
      border-color: rgb(102, 102, 102);
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      color: white;
      background-color: rgba(0, 0, 0, 0.6);

      .ksEuhS {
        display: flex;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        height: px2rem(348);
        left: 0px;
        right: 0px;
        font-size: px2rem(24);
        line-height: px2rem(40);
        font-weight: 300;

        .cTJIxw {
          font-size: px2rem(34);
          font-weight: bold;
          text-align: center;
          display: flex;
          flex-direction: column;
        }

        .isHFlM {
          margin-top: px2rem(40);
        }

        .fReABp {
          height: px2rem(40);
          display: flex;
          flex-direction: row;
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          align-items: center;
          margin-top: px2rem(60);

          .insUpY {
            width: px2rem(220);
            height: px2rem(50);
            line-height: px2rem(50);
            background: #308971;
            border-radius: px2rem(40);
            border: none;
            color: white;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: px2rem(20);
            font-weight: 300;
            margin-right: px2rem(40);
          }

          .iZGBsp {
            font-size: px2rem(20);
            line-height: px2rem(50);
            color: white;

            img {
              width: px2rem(25);
              height: px2rem(25);
              margin-right: px2rem(10);
            }

            .kFYMXL {
              height: px2rem(50);
              border: 0.02rem solid #308971;
              border-radius: px2rem(40);
              text-align: center;
              display: flex;
              flex-direction: row;
              -webkit-box-align: center;
              align-items: center;
              padding: 0px px2rem(40) !important;
            }
          }
        }
      }
    }
    .iaIRJu {
    width: 100vw;
    height: 5rem;
    position: relative;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: -1;
}
}
.homepage_bg_1 {
    position: relative;
    width: 100vw;
    margin-top: 0.8rem;
    background: #fff;
    .home_content{
      margin: auto;
      width: px2rem(1253);
      overflow: hidden;
      .introduce{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .introduce-title{
          position: relative;
          padding-left: px2rem(25);
          height: px2rem(45);
          img{
            width: px2rem(158);
            height: px2rem(47);
          }
          &::before{
            position: absolute;
            top: px2rem(1);
            left: 0;
            content: '';
            width: px2rem(5);
            height: px2rem(45);
            background: #308971;
            border-radius: px2rem(3);
          }
        }
        .introduce-to{
          color:#308971;
          font-size: px2rem(20);
          a{
            color:#308971;
          }
        }
        .introduce-content{
          position: relative;
          p{
            position: absolute;
            top: px2rem(10);
            left: 0; 
            font-size: px2rem(22);
            color: rgba(0,0,0,0.65);
          }
          img{
            width: px2rem(907);
            height: px2rem(647);
          }
        }
        .introduce-text{
          width: px2rem(370);
          padding-left: px2rem(20);
          box-sizing: border-box;
          font-size: px2rem(16);
          line-height: px2rem(26);
          .introduce-text-title{
            position: relative;
            margin-top: px2rem(26);
            font-size: px2rem(22);
            font-weight: bold;
            &::before{
              position: absolute;
              left: px2rem(-20);
              top: px2rem(10);
              content: '';
              width: px2rem(8);
              height: px2rem(8);
              background: #308971;
              border-radius: 50%;
            }
          }
        }
        .datas{
          width: px2rem(300);
          height: px2rem(317);
          background: #FFFFFF;
          box-shadow: 0px px2rem(1) px2rem(10) 0px rgba(0,0,0,0.05);
          border-radius: px2rem(10);
          display: flex;
          flex-direction: column;
          align-items: center;
          .icon{
            margin-top: px2rem(20);
            width: px2rem(70);
            height: px2rem(70);
          }
          .names{
            margin-top: px2rem(10);
            font-size: px2rem(28);
            line-height: px2rem(40);
          }
          .line{
            margin-top: px2rem(10);
            width: px2rem(112);
            height: 1px;
            border: 1px solid #F5F5F5;
          }
          .up{
            margin-top: px2rem(10);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            height: px2rem(140);
            .one{
              height: px2rem(22);
              line-height: px2rem(22);
              display: flex;
              align-items: center;
              justify-content: space-between;
              .up-name{
                margin-right: px2rem(10);
                width: px2rem(145);
                color: rgba(0,0,0,0.65);
                font-size: px2rem(16);
                text-align: right;
              }
              .up-line{
                width: px2rem(80);
                height: px2rem(6);
                background: #F5F5F5;
                border-radius: px2rem(8);
                .progress-bg{
                  border-radius: px2rem(8);
                  background: #F5222D;
                  height: px2rem(6);
                  animation: animationLine 4s forwards;
                  &.down{
                    background: #308971;
                  }
                }
                @keyframes animationLine {
                  0% {
                    width: 0;
                  }

                  100% {
                    width: 100%;
                  }
                }
              }
              .up-num{
                display: flex;
                align-items: center;
                width: px2rem(74);
                font-size: px2rem(14);
                .add{
                  color: #F5222D;
                }
                .down{
                  color: #308971;
                }
              }
            }
          }
        }
        .godDone{
          margin: px2rem(25) 0;
          display: flex;
          align-items: center;
          justify-content: space-around;
          .icon{
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: px2rem(52);
            height: px2rem(52);
            background: #FFFFFF;
            border-radius: px2rem(10);
            img{
              width: px2rem(30);
              height: px2rem(30);
            }
          }
          .text{
            margin-left: px2rem(20);
            font-size: px2rem(16);
            color: rgba(0,0,0,0.65);
            line-height: px2rem(22);
            .names{
              margin-bottom: px2rem(10);
              color: rgba(0,0,0,0.85);
              font-size: px2rem(28);
              font-weight: 400;
            }
          }
        }
        &.introduce-first{
          margin-top: px2rem(119);
        }
        &.introduce-second{
          margin-top: px2rem(7);
        }
        &.introduce-3{
          padding: 0 px2rem(10);
          margin: auto;
          width: px2rem(1250);
          height: px2rem(317);
          box-sizing: border-box;
        }
        &.introduce-4{
          margin: auto;
          margin-top: px2rem(20);
          width: px2rem(1198);
        }
      }
    }
    .colorDiv{      
      width: 100%;
      height: px2rem(513);
      background: rgba(48,137,113,0.05);
      margin-top: px2rem(-465);
    }
}
</style>
