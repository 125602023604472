<template>
  <div class="home1">
    <div class="home4">
      <div class="home4_cotent">
        <div class="home4_main">
          <div class="home4_title">
            <div class="home4_left">
              <div class="home4_p1">
                <div class="home_title_line"></div>
                <div class="home_title_pt">商城与大数据平台</div>
              </div>
              <div class="home_title_xs">携手共进，打造制造业企业联盟</div>
            </div>
            <div class="home_title_product">
              <a href="/productoverview"
                >详细产品<i class="el-icon-right home_title_icon"></i
              ></a>
            </div>
          </div>

          <div class="home4_cardList">
            <div class="home4_card">
              <div class="home4_card_div">
                <img
                  :src="require('@/assets/image/gongyeping.png')"
                  alt=""
                  class="card_img1"
                />
              </div>
              <div class="home4_card_title">数壤工业品商城</div>
              <div class="home4_card_content card_one">
                专业工业品商城，属于制造业的专属商城
              </div>
            </div>

            <div class="home4_card">
              <div class="home4_card_div">
                <img
                  :src="require('@/assets/image/dianshang.png')"
                  alt=""
                  class="card_img2"
                />
              </div>
              <div class="home4_card_title">数壤跨境电商</div>
              <div class="home4_card_content">打通境外渠道，开辟新鲜路径。</div>
            </div>

            <div class="home4_card">
              <div class="home4_card_div">
                <img
                  :src="require('@/assets/image/zhuanjia.png')"
                  alt=""
                  class="card_img3"
                />
              </div>
              <div class="home4_card_title">数壤专家中心</div>
              <div class="home4_card_content">集中智囊团队，解决企业问题</div>
            </div>

            <div class="home4_card">
              <div class="home4_card_div">
                <img
                  :src="require('@/assets/image/kecheng.png')"
                  alt=""
                  class="card_img4"
                />
              </div>
              <div class="home4_card_title">数壤精选课程</div>
              <div class="home4_card_content">针对企业痛点，知识引领力量</div>
            </div>

            <div class="home4_card">
              <div class="home4_card_div">
                <img
                  :src="require('@/assets/image/chanye.png')"
                  alt=""
                  class="card_img5"
                />
              </div>
              <div class="home4_card_title">数壤产业大数据平台</div>
              <div class="home4_card_content">聚沙成塔，打造制造业伊甸</div>
            </div>

            <div class="home4_card">
              <div class="home4_card_div">
                <img
                  :src="require('@/assets/image/hangye.png')"
                  alt=""
                  class="card_img6"
                />
              </div>
              <div class="home4_card_title">数壤行业大数据平台</div>
              <div class="home4_card_content">比肩共进，整体行业升级</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home5">
      <div class="home5_title">
        <div class="home5_title_line"></div>
        <div class="home5_title_p1">工业品碳足迹</div>
      </div>
      <div class="home5_explain">
        基于生产、能源、设备一体化工业大数据，多种AI和算法协同，应用区块链技术构筑起关键节点数据加密和确权，形成以真实数据为底层，产业链为依托的工业品碳足迹服务平台。
      </div>
      <div class="home5_img_div">
        <img :src="dm_metaverse" />
      </div>
    </div>
    <div class="home6">
      <div class="home6_title">
        <div class="home6_title_line"></div>
        <div class="home6_title_p1">快速走进数壤</div>
      </div>
      <div class="bottom">
        <div>
          <swiper :options="swiperOption">
            <swiper-slide v-for="(slide, key) in swiperList" :key="key">
              <div style="cursor: pointer">
                <div class="item_container">
                  <span class="apparatus_name">{{ slide.name }}</span>
                </div>
                <div class="apparatus_img">
                  <img :src="slide.swiper_img" />
                </div>
              </div>
            </swiper-slide>
            <!-- <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div> -->
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </div>
    </div>
    <div class="home7">
      <div class="home7_title">
        <div class="home7_title_line"></div>
        <div class="home7_title_p1">部分客户及合作伙伴</div>
      </div>
      <div class="home7_info">政府、园区及机构、行业企业、合作院校</div>
      <div class="home7_img_div">
        <div v-for="(item, key) in imgArr" :key="key">
          <img :src="item" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      showNum: false,
      logo: require("../../assets/image/logo.png"),
      gongzhonghao: require("../../assets/image/gongzhonghao.png"),
      douyinghao: require("../../assets/image/douyinghao.png"),
      imgArr: [
        require("../../assets/image/shandong.png"),
        require("../../assets/image/boshan.png"),
        require("../../assets/image/maanshan.png"),
        require("../../assets/image/wuzhong.png"),
        require("../../assets/image/CAICT.png"),
        require("../../assets/image/SDIIT.png"),
        require("../../assets/image/TLSP.png"),
        require("../../assets/image/Universad.png"),
        require("../../assets/image/CAS.png"),
        require("../../assets/image/huawei.png"),
        require("../../assets/image/CHG.png"),
        require("../../assets/image/ZENCAPITAL.png"),
        require("../../assets/image/dongfeng.png"),
        require("../../assets/image/Simand.png"),
        require("../../assets/image/Provac.png"),
        require("../../assets/image/MHG.png"),
        require("../../assets/image/ZENTO.png"),
        require("../../assets/image/xinheng.png"),
        require("../../assets/image/czschool.png"),
        require("../../assets/image/suzhida.png"),
        require("../../assets/image/sukeda.png"),
        require("../../assets/image/suda.png"),
        require("../../assets/image/jsschool.png"),
        require("../../assets/image/nanligong.png"),
      ],
      swiperList: [
        {
          name: "供应链管理",
          swiper_img: require("../../assets/image/gongyinglian.png"),
        },
        {
          name: "制造管理",
          swiper_img: require("../../assets/image/zhizao.png"),
        },
        {
          name: "数壤商城",
          swiper_img: require("../../assets/image/shangcheng.png"),
        },
        {
          name: "订单管理",
          swiper_img: require("../../assets/image/order.png"),
        },
        {
          name: "人才中心",
          swiper_img: require("../../assets/image/rencai.png"),
        },
        {
          name: "设备OEE",
          swiper_img: require("../../assets/image/oee.png"),
        },
        {
          name: "数据分析服务",
          swiper_img: require("../../assets/image/data.png"),
        },
        {
          name: "数壤大学",
          swiper_img: require("../../assets/image/school.png"),
        },
        {
          name: "物联设备",
          swiper_img: require("../../assets/image/IOT.png"),
        },
        {
          name: "Boss中心",
          swiper_img: require("../../assets/image/bosscenter.png"),
        },
        {
          name: "能源双碳管理",
          swiper_img: require("../../assets/image/nengyuan.png"),
        },
      ],
      //轮播图配置项
      swiperOption: {
        //开启这个参数来计算每个slide的progress，Swiper的progress无需设置即开启
        watchSlidesProgress: true,
        //设置slider容器能够同时显示的slides数量
        // slidesPerView: "auto",
        slidesPerView: 5,
        // slidesPerView:4.2,
        //active图片居中
        centeredSlides: true,
        //开启loop模式：会在原本slide前后复制若干个slide(默认一个)并在合适的时候切换，让Swiper看起来是循环的。
        loop: true,
        //在loop模式下使用slidesPerview:'auto'，还需使用该参数设置所要用到的loop个数(一般设置大于可视slide个数2个即可)。
        loopedSlides: 5,
        //轮播设置
        autoplay: false,
        spaceBetween: 20,
        // autoplay: {
        //   //点击后仍能自动开始轮播
        //   disableOnInteraction: false,
        // },
        //pre, next按钮
        navigation: {
          // nextEl: ".swiper-button-next",
          // prevEl: ".swiper-button-prev",
        },
        //使用分页器导航。分页器可使用小圆点样式（默认）、分式样式或进度条样式。
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        //不允许拖动
        allowTouchMove: false,
        on: {
          //回调函数，每当设置Swiper开始过渡动画时执行。transtion获取到的是Swiper的speed值
          setTransition: function (transition) {
            for (var i = 0; i < this.slides.length; i++) {
              var slide = this.slides.eq(i);
              slide.transition(transition);
            }
          },
        },

        // Some Swiper option/callback...
      },
      dm_metaverse: require("../../assets/image/metaverse.png"),
      cardList: [
        {
          img: require("../../assets/image/gongyeping.png"),
          title: "数壤工业品商城",
          content: "专业工业品商城，属于制造业的专属商城",
        },
        {
          img: require("../../assets/image/dianshang.png"),
          title: "数壤跨境电商",
          content: "打通境外渠道，开辟新鲜路径。",
        },
        {
          img: require("../../assets/image/zhuanjia.png"),
          title: "数壤专家中心",
          content: "集中智囊团队，解决企业问题",
        },
        {
          img: require("../../assets/image/kecheng.png"),
          title: "数壤精选课程",
          content: "针对企业痛点，知识引领力量",
        },
        {
          img: require("../../assets/image/chanye.png"),
          title: "数壤产业大数据平台",
          content: "聚沙成塔，打造制造业伊甸",
        },
        {
          img: require("../../assets/image/hangye.png"),
          title: "数壤行业大数据平台",
          content: "比肩共进，整体行业升级",
        },
      ],
    };
  },
  created() {},
  destroyed() {},
  mounted() {
    $(".swiper-slide")
      .off()
      .click(() => {
        location.href = "/productoverview";
      });
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.swiper-pagination-bullet-active {
  background-color: #308971;
}
//1920*1080为设计稿，头部字体大小为25px
$ratio: 1920 / 76.8; // 缩放比   25

// 返回对应屏幕下的rem值
@function px2rem($px) {
  @return $px / $ratio + rem;
}

a {
  color: white;
}
.home1 {
  background: #fff;
}

.home4 {
  background: #fff;
  width: 100%;
  padding-top: px2rem(80);
  .home4_cotent {
    height: px2rem(562);
    background: url("../../assets/image/dm_bg.png") no-repeat;
    background-size: contain;
    .home4_main {
      width: 60%;
      margin: 0 auto;
      .home4_title {
        display: flex;
        // justify-content: space-around;
        color: #fff;
        .home4_left {
          padding-top: px2rem(80);
          .home4_p1 {
            display: flex;
            font-family: PingFangSC-Medium, PingFang SC;

            .home_title_line {
              width: px2rem(5);
              height: px2rem(45);
              background: #fff;
              border-radius: px2rem(3);
              margin-right: px2rem(20);
            }
            .home_title_pt {
              height: px2rem(45);
              font-weight: 500;
              font-size: px2rem(32);
              color: rgba(255, 255, 255, 0.85);
            }
          }
          .home_title_xs {
            margin-top: px2rem(20);
            font-family: PingFangSC-Regular, PingFang SC;
            font-size: px2rem(20);
            font-weight: 400;
            color: rgba(255, 255, 255, 0.85);
            height: px2rem(28);
            line-height: px2rem(28);
          }
        }
        .home_title_product {
          margin-top: px2rem(113);
          margin-left: auto;
          a {
            color: #fff;
            .home_title_icon {
              margin-left: px2rem(10);
            }
          }
        }
      }
      .home4_cardList {
        margin-top: px2rem(32);
        display: flex;
        // justify-content: space-around;

        .home4_card {
          width: px2rem(184);
          height: px2rem(247);
          // padding-bottom: px2rem(41);
          background: #fff;
          border-radius: px2rem(10);
          margin-right: px2rem(19);
          &:last-child {
            margin-right: 0;
          }
          .home4_card_div {
            width: px2rem(80);
            height: px2rem(80);
            margin: px2rem(30) auto px2rem(19);
            background: rgba(48, 137, 113, 0.1);
            text-align: center;
            line-height: px2rem(80);
            border-radius: 50%;
            .card_img1 {
              width: px2rem(40);
              height: px2rem(30);
              margin: px2rem(5) 0;
            }
            .card_img2 {
              width: px2rem(36);
              height: px2rem(36);
              margin: px2rem(5);
            }
            .card_img3 {
              width: px2rem(38);
              height: px2rem(31);
              margin: px2rem(2) 0 px2rem(5);
            }
            .card_img4 {
              width: px2rem(34);
              height: px2rem(40);
              margin: px2rem(1) px2rem(4);
            }
            .card_img5 {
              width: px2rem(38);
              height: px2rem(37);
              margin: px2rem(2) px2rem(2) px2rem(3);
            }
            .card_img6 {
              width: px2rem(40);
              height: px2rem(36);
              margin: px2rem(4) px2rem(2);
            }
          }
          .home4_card_title {
            height: px2rem(25);
            font-size: px2rem(18);
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
            line-height: px2rem(25);
            text-align: center;
            margin-bottom: px2rem(8);
          }
          .card_one {
            width: px2rem(160) !important;
          }
          .home4_card_content {
            width: px2rem(112);
            // height: px2rem(44);
            font-size: px2rem(16);
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.65);
            line-height: px2rem(22);
            margin: 0 auto;
            text-align: center;
          }
        }
      }
    }
  }
}
.home5 {
  width: 60%;
  margin: px2rem(80) auto;
  .home5_title {
    display: flex;
    .home5_title_line {
      width: px2rem(5);
      height: px2rem(45);
      background: #308971;
      border-radius: px2rem(3);
      margin-right: px2rem(20);
    }
    .home5_title_p1 {
      height: px2rem(45);
      font-size: px2rem(32);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: px2rem(45);
    }
  }
  .home5_explain {
    width: 100%;
    height: px2rem(56);
    font-size: px2rem(20);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: px2rem(28);
    margin-top: px2rem(20);
  }
  .home5_img_div {
    width: 100%;
    margin-top: px2rem(40);
    img {
      width: 100%;
    }
  }
}
.home6 {
  width: 60%;
  margin: px2rem(50) auto;
  .home6_title {
    display: flex;
    margin-bottom: px2rem(20);
    .home6_title_line {
      width: px2rem(5);
      height: px2rem(45);
      background: #308971;
      border-radius: px2rem(3);
      margin-right: px2rem(20);
    }
    .home6_title_p1 {
      height: px2rem(45);
      font-size: px2rem(32);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: px2rem(45);
    }
  }
  .bottom {
    height: px2rem(400);
    width: 100%;
    // box-sizing: border-box;
    overflow: hidden;
    margin: 0 auto;

    .item_container {
      width: 100%;
      text-align: center;

      .apparatus_name {
        height: px2rem(28);
        font-size: px2rem(20);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: px2rem(28);
      }
    }

    .apparatus_img {
      //   padding: 7% 0.5%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: px2rem(240);
        height: px2rem(333);
        // width: 100%;
        // height: 100%;
      }
    }
  }

  .swiper-slide {
    width: 22%;
    transform: scale(0.8);
  }

  .swiper-button-prev {
    &::after {
      color: #fff;
      margin-left: px2rem(40);
      font-size: px2rem(40);
    }
  }

  .swiper-button-next {
    &::after {
      color: #fff;
      margin-right: px2rem(40);
      font-size: px2rem(40);
    }
  }

  .swiper-slide-active,
  .swiper-slide-duplicate-active {
    transform: scale(1);
    .item_container {
      .apparatus_name {
        height: px2rem(33);
        font-size: px2rem(24);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: px2rem(33);
      }
    }
    .apparatus_img {
      // width: 100%;
      // height: 100%;
      width: px2rem(240);
      height: px2rem(335);
    }
  }
  .swiper-container {
    overflow: inherit;
  }
  .swiper-pagination {
    bottom: px2rem(-30);
  }
  .swiper-pagination-bullet-active {
  }
}
.home7 {
  background: rgba(48, 137, 113, 0.05);
  padding: px2rem(62) 20% px2rem(80);

  .home7_title {
    display: flex;
    margin-bottom: px2rem(20);
    .home7_title_line {
      width: px2rem(5);
      height: px2rem(45);
      background: #308971;
      border-radius: px2rem(3);
      margin-right: px2rem(20);
    }
    .home7_title_p1 {
      height: px2rem(45);
      font-size: px2rem(32);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: px2rem(45);
    }
  }
  .home7_info {
    margin-top: px2rem(20);
    height: px2rem(28);
    font-size: px2rem(20);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: px2rem(28);
    margin-bottom: px2rem(40);
  }
  .home7_img_div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    div {
      // margin-right: px2rem(10);
      margin-bottom: px2rem(30);
      img {
        width: px2rem(183);
        height: px2rem(80);
      }
    }
  }
}
</style>
